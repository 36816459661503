/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {
    Col, Row, Progress, Divider
} from 'antd';
import { map, sortBy } from 'underscore';
import { ALL_SELECTS_IMAGES, ALL_UNKILLED_IMAGES, ALL_UNKILLED_SELECTS_IMAGES, ROLE_ACTOR, ALL_IMAGES, ROLE_ADMIN, ROLE_RESELLER, ROLE_AGENT, ROLE_VIEWER, ROLE_ROOT_ADMIN, ROLE_RESELLER_ADMIN } from '../../core/utils/value';

function ModalContent(props) {
    const {
        type,
        currentUser,
        userSoloTotalImages,
        userGroupTotalImages,
        userLowerGroupTotalImages,
        productionTotalImages,
        killedImagesLink,
        filteredUserStats,
        userUpperGroupTotalImages,
        approveSelectedImages,
        totalUnkilledImages,
    } = props;

    let sortedUserStats = sortBy(filteredUserStats, 'name').reverse();
    sortedUserStats = sortBy(sortedUserStats, 'userType').reverse();

    const sortedActorStats = sortedUserStats.filter((userStat) => userStat.userType === ROLE_ACTOR);
    const sortedNonActorStats = sortedUserStats.filter((userStat) => userStat.userType !== ROLE_ACTOR);

    const chartGreenColor = '#52c41a';
    const chartBlueColor = '#1890ff';
    const chartRedColor = '#c00000';

    let upperUsers = 0;
    switch (type) {
    case 'user': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;
        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userGroupTotalImages) * 100);
        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.soloKills}</strong>
                            {' '}
                            (
                            {soloKillAllowanceUsedPercentage}
                            %) of your available solo kill allowance of
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' '}
                            images (
                            {soloKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your solo kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.groupKills}</strong>
                            {' '}
                            (
                            {groupKillAllowanceUsedPercentage}
                            %) of your available group kill allowance of
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' '}
                            images (
                            {groupKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            You appear in a total of
                            {' '}
                            <strong>{currentUser.userTotalImages}</strong>
                            {' '}
                            images across this production.
                            {' '}
                            <strong>{currentUser.userSoloImages}</strong>
                            {' '}
                            of these
                            images are solo images, and
                            {' '}
                            <strong>{currentUser.userGroupImages}</strong>
                            {' '}
                            are group images. You may kill up to
                            {' '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            solo
                            images, and
                            {' '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            group images in total.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'upper': {
        const totalAllowedSoloKillImages = Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages);
        const remainingAllowedSoloKillImages = totalAllowedSoloKillImages - currentUser.soloKills;
        const soloKillAllowanceUsedPercentage = Math.ceil(currentUser.soloKills / Math.ceil((currentUser.soloKillLimit / 100) * userSoloTotalImages) * 100);
        const soloKillAllowanceRemainingPercentage = 100 - soloKillAllowanceUsedPercentage;

        let soloColor = chartGreenColor;
        if (soloKillAllowanceUsedPercentage >= 30 && soloKillAllowanceUsedPercentage < 70) {
            soloColor = chartBlueColor;
        } else if (soloKillAllowanceUsedPercentage >= 70) {
            soloColor = chartRedColor;
        }

        const totalAllowedGroupKillImages = Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages);
        const remainingAllowedGroupKillImages = totalAllowedGroupKillImages - currentUser.groupKills;
        const groupKillAllowanceUsedPercentage = Math.ceil(currentUser.groupKills / Math.ceil((currentUser.groupKillLimit / 100) * userLowerGroupTotalImages) * 100);
        const groupKillAllowanceRemainingPercentage = 100 - groupKillAllowanceUsedPercentage;

        let groupColor = chartGreenColor;
        if (groupKillAllowanceUsedPercentage >= 30 && groupKillAllowanceUsedPercentage < 70) {
            groupColor = chartBlueColor;
        } else if (groupKillAllowanceUsedPercentage >= 70) {
            groupColor = chartRedColor;
        }

        const totalAllowedUpperKillImages = Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages);
        const remainingAllowedUpperKillImages = totalAllowedUpperKillImages - currentUser.upperGroupKills;
        const upperKillAllowanceUsedPercentage = Math.ceil(currentUser.upperGroupKills / Math.ceil((currentUser.upperGroupKillLimit / 100) * userUpperGroupTotalImages) * 100);
        const upperKillAllowanceRemainingPercentage = 100 - upperKillAllowanceUsedPercentage;

        let upperColor = chartGreenColor;
        if (upperKillAllowanceUsedPercentage >= 30 && upperKillAllowanceUsedPercentage < 70) {
            upperColor = chartBlueColor;
        } else if (upperKillAllowanceUsedPercentage >= 70) {
            upperColor = chartRedColor;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col sm={12} xs={24}>
                        <h2>Solo</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={soloColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={soloKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedSoloKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.soloKills}
                                            /
                                            {totalAllowedSoloKillImages}
                                            {' '}
                                            (
                                            {soloKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>

                    </Col>
                    <Col sm={12} xs={24}>
                        <h2>Lower Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={groupColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={groupKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedGroupKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.groupKills}
                                            /
                                            {totalAllowedGroupKillImages}
                                            {' '}
                                            (
                                            {groupKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                    <Col xs={24} style={{ textAlign: 'center' }}>
                        <h2>Upper Group</h2>
                        <div>
                            <Progress
                                width={200}
                                className="allowanceProgress"
                                strokeLinecap="square"
                                strokeColor={upperColor}
                                type="circle"
                                strokeWidth={8}
                                style={{ fontSize: 10 }}
                                percent={upperKillAllowanceUsedPercentage}
                                format={() => (
                                    <div>
                                        <p className="remainingTitle">Kills remaining:</p>
                                        <p className="remainingNumber">{remainingAllowedUpperKillImages}</p>

                                        <p className="remainingBottomPercentage">
                                            {currentUser.upperGroupKills}
                                            /
                                            {totalAllowedUpperKillImages}
                                            {' '}
                                            (
                                            {upperKillAllowanceUsedPercentage}
                                            %)
                                        </p>
                                        <p className="remainingBottomText">used</p>
                                    </div>
                                )} />
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col xs={24}><h2>Summary</h2></Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Solo:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.soloKills}</strong>
                            {' '}
                            (
                            {soloKillAllowanceUsedPercentage}
                            %) of your available solo kill allowance of
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedSoloKillImages}</strong>
                            {' '}
                            images (
                            {soloKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your solo kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Lower Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.groupKills}</strong>
                            {' '}
                            (
                            {groupKillAllowanceUsedPercentage}
                            %) of your available lower group kill allowance of
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedGroupKillImages}</strong>
                            {' '}
                            images (
                            {groupKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your lower group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            <strong>Upper Group:</strong>
                            {' '}
                            You have used
                            <strong>{currentUser.upperGroupKills}</strong>
                            {' '}
                            (
                            {upperKillAllowanceUsedPercentage}
                            %) of your available upper group kill allowance of
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' '}
                            images, meaning you have
                            <strong>{remainingAllowedUpperKillImages}</strong>
                            {' '}
                            images (
                            {upperKillAllowanceRemainingPercentage}
                            %) remaining that you can kill within your upper group kill allowance.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            You appear in a total of
                            {' '}
                            <strong>{productionTotalImages}</strong>
                            {' '}
                            images across this production.
                            {' '}
                            <strong>{userSoloTotalImages}</strong>
                            {' '}
                            of these
                            images are solo images,
                            {' '}
                            <strong>{userLowerGroupTotalImages}</strong>
                            {' '}
                            are lower group images, and
                            {' '}
                            <strong>{userUpperGroupTotalImages}</strong>
                            {' '}
                            are upper group images. You may kill up to
                            {' '}
                            <strong>{totalAllowedSoloKillImages}</strong>
                            {' '}
                            solo
                            images, up to
                            {' '}
                            <strong>{totalAllowedGroupKillImages}</strong>
                            {' '}
                            lower group images, and
                            {' '}
                            <strong>{totalAllowedUpperKillImages}</strong>
                            {' '}
                            upper group images in total.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Link className="ant-btn regular-button" to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }

    case 'agent': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center', maxWidth: '93vw' }}>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total Images:
                            {' '}
                            {productionTotalImages}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total Unkilled:
                            {' '}
                            {approveSelectedImages}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <p>
                            Total Unkilled Selects:
                            {' '}
                            {totalUnkilledImages}
                        </p>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                    <Col xs={24}>
                        <h4>Kill Allowance mechanism</h4>
                        <h2>Solo & Group</h2>
                    </Col>
                </Row>
                <div style={{ overflowX: 'auto', width: '100%' }}>
                    <table style={{ width: '100%', minWidth: '1500px' }}>
                        <thead>
                            <tr>
                                <th colSpan={1} style={{ color: 'transparent' }}>Talent</th>
                                <th colSpan={6}><h3>Total Images / Selects</h3></th>
                                <th colSpan={2}><h3>Kill Allowance</h3></th>
                                <th colSpan={4}><h3>Unkilled Images / Selects</h3></th>
                            </tr>
                            <tr className="kill-allowance-widget-header-row">
                                <th className="managed-width">Talent</th>
                                <th>Total</th>
                                <th className="selects">Selects</th>
                                <th>Solo</th>
                                <th className="selects">Selects</th>
                                <th>Group</th>
                                <th className="selects">Selects</th>

                                <th>Killed Allowance Solo</th>
                                <th className="padding-after">Killed Allowance Group</th>

                                <th className="padding-before-20">Unkilled Solo</th>
                                <th className="selects">Selects</th>
                                <th>Unkilled Group</th>
                                <th className="selects">Selects</th>
                            </tr>
                        </thead>
                        <tbody>
                            {map(sortedActorStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 ? (
                                <tr key={userStatIndex}>
                                    {/* name */}
                                    <td className="managed-width" style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</td>

                                    {/* total images */}
                                    <td>{userStat.userSoloImages + userStat.userGroupImages}</td>

                                    {/* total selects */}
                                    <td className="selects">{userStat.userSoloSelectedImages + userStat.userGroupSelectedImages}</td>

                                    {/* solo */}
                                    <td>{userStat.userSoloImages}</td>

                                    {/* solo selects */}
                                    <td className="selects">{userStat.userSoloSelectedImages}</td>

                                    {/* group */}
                                    <td>{userStat.userGroupImages}</td>

                                    {/* group selects */}
                                    <td className="selects">{userStat.userGroupSelectedImages}</td>

                                    {/* killed allowance solo */}
                                    <td>{userStat.soloKillLimit === 0 ? 'Unlimited' : `${userStat.soloKilled} of ${Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)} (${Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}%)`}</td>

                                    {/* killed allowance group */}
                                    <td className="padding-after">{userStat.groupKillLimit === 0 ? 'Unlimited' : `${userStat.groupKilled} of ${Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)} (${Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}%)`}</td>

                                    {/* unkilled solo */}
                                    <td>{userStat.soloUnkilled}</td>

                                    {/* unkilled solo selects */}
                                    <td className="selects">{userStat.userSoloUnkilledSelects}</td>

                                    {/* unkilled group */}
                                    <td>{userStat.groupUnkilled}</td>

                                    {/* unkilled group selects */}
                                    <td className="selects">{userStat.userGroupUnkilledSelects}</td>
                                </tr>
                            ) : null))}
                        </tbody>
                    </table>
                </div>
                {sortedNonActorStats.length > 0 && (
                    <div style={{ maxWidth: '800px', width: '100%', overflowX: 'auto', margin: '0 auto', paddingTop: 20, paddingBottom: 20 }}>
                        <table className="agent-stats-table">
                            <thead>
                                <tr>
                                    <th className="lg">User</th>
                                    <th className="sm">User Type</th>
                                    <th className="md">Can View</th>
                                    <th>Watermarks</th>
                                    <th className="lg">Downloads</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(sortedNonActorStats, (userStat, userStatIndex) => (
                                    <tr key={userStatIndex}>
                                        <td className="lg">{userStat.name}</td>
                                        <td className="sm">{userStat.formattedUserType}</td>
                                        <td className="md">{userStat.canView}</td>
                                        <td>{userStat.showWatermark}</td>
                                        <td className="lg">{userStat.formattedAllowImageDownload}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <Row style={{ marginTop: 30, marginBottom: 15 }}>
                    <Col xs={24}>
                        <h4>Kill Allowance mechanism</h4>
                        <h2>
                            Solo, lower group,
                            <br />
                            {' '}
                            upper group
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} />
                    <Col xs={9}><h3>Total images</h3></Col>
                    <Col xs={6}><h3>Kill allowance</h3></Col>
                </Row>
                <Row>
                    <Col xs={7} />
                    <Col xs={2} className="kill-allowance-widget-header">Total</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={2} className="kill-allowance-widget-header">Group Upper</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Solo</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group</Col>
                    <Col xs={3} className="kill-allowance-widget-header">Group Upper</Col>
                </Row>
                {map(sortedUserStats, (userStat, userStatIndex) => {
                    if (userStat.allowanceType !== 2) {
                        return null;
                    }

                    upperUsers += 1;
                    return (
                        <Row key={userStatIndex} style={{ marginTop: 10 }}>
                            <Col xs={7} style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</Col>

                            { /* Total Images */}
                            <Col
                                xs={2}>
                                {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                            </Col>

                            { /* Total Solo */}
                            <Col
                                xs={2}>
                                {userStat.userSoloImages}
                            </Col>

                            { /* Total Solo */}
                            <Col
                                xs={2}>
                                {userStat.userGroupImages}
                            </Col>

                            <Col
                                xs={2}>
                                {userStat.userUpperGroupImages}
                            </Col>

                            { /* Solo */
                                userStat.soloKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.soloKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.soloKilled / ((userStat.soloKillLimit === 0 ? 1 : Math.ceil((userStat.soloKillLimit / 100) * userStat.userSoloImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                            { /* Group */
                                userStat.groupKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.groupKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.groupKilled / ((userStat.groupKillLimit === 0 ? 1 : Math.ceil((userStat.groupKillLimit / 100) * userStat.userGroupImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                            { /* Group */
                                userStat.upperGroupKillLimit === 0
                                    ? <Col xs={3}>Unlimited</Col>
                                    : (
                                        <Col
                                            xs={3}>
                                            {userStat.upperGroupKilled}
                                            {' '}
                                            of
                                            {' '}
                                            {Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)}
                                            {' '}
                                            (
                                            {Math.ceil(userStat.upperGroupKilled / ((userStat.upperGroupKillLimit === 0 ? 1 : Math.ceil((userStat.upperGroupKillLimit / 100) * userStat.userUpperGroupImages)) || 1) * 100)}
                                            %)
                                        </Col>
                                    )
                            }

                        </Row>
                    );
                })}

                {upperUsers === 0
                    ? (
                        <Row>
                            <Col xs={24} style={{ textAlign: 'left' }}>There are no users...</Col>
                        </Row>
                    )
                    : false}
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={24}>
                        <Link to={killedImagesLink}>View all killed images</Link>
                    </Col>
                </Row>
            </div>
        );
    }
    case 'viewer': {
        return (
            <div className="kill-allowance-widget-inner-div" style={{ textAlign: 'center' }}>
                {currentUser.SelectImageViewOption === ALL_UNKILLED_IMAGES && (
                    <div style={{ minWidth: '600px', margin: '0 auto', width: '100%' }}>
                        <table style={{ width: '100%', margin: '0 auto' }}>
                            <thead>
                                <tr>
                                    <th colSpan={4}>
                                        <h3>Unkilled</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="kill-allowance-widget-header" style={{ color: 'transparent' }}>Talent</th>
                                    <th className="kill-allowance-widget-header w-100">Total</th>
                                    <th className="kill-allowance-widget-header w-100">Solo</th>
                                    <th className="kill-allowance-widget-header w-100">Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                    <tr key={userStatIndex} style={{ marginTop: 10 }}>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</td>
                                        { /* Total Images */}
                                        <td>
                                            {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                                        </td>
                                        {
                                            /* Solo */
                                            userStat.userSoloImages + userStat.userGroupImages === 0
                                                ? <td>0</td>
                                                : (
                                                    <td>
                                                        {userStat.soloUnkilled}
                                                    </td>
                                                )
                                        }

                                        {
                                            /* Group */
                                            userStat.userSoloImages + userStat.userGroupImages === 0
                                                ? <td>0</td>
                                                : (
                                                    <td>
                                                        {userStat.groupUnkilled}
                                                    </td>
                                                )
                                        }
                                    </tr>
                                ) : null))}
                            </tbody>
                        </table>
                    </div>
                )}
                {currentUser.SelectImageViewOption === ALL_SELECTS_IMAGES && (
                    <div style={{ width: '750px', margin: '0 auto' }}>
                        <table style={{ width: '100%', margin: '0 auto' }}>
                            <thead>
                                <tr>
                                    <th colSpan={1} style={{ color: 'transparent' }}>Talent</th>
                                    <th colSpan={1} style={{ color: 'transparent' }}>Total</th>
                                    <th colSpan={2} style={{ textAlign: 'center' }}>
                                        <h3>All Selects</h3>
                                    </th>
                                    <th colSpan={2} style={{ textAlign: 'center' }}>
                                        <h3>Unkilled Selects</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="kill-allowance-widget-header" style={{ color: 'transparent' }}>Talent</th>
                                    <th className="kill-allowance-widget-header w-100">Total</th>
                                    <th className="kill-allowance-widget-header w-100">Solo</th>
                                    <th className="kill-allowance-widget-header w-100">Group</th>
                                    <th className="kill-allowance-widget-header w-100">Solo</th>
                                    <th className="kill-allowance-widget-header w-100">Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                    <tr key={userStatIndex} style={{ marginTop: 10 }}>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</td>
                                        { /* Total Images */}
                                        <td className="w-100">
                                            {userStat.userSoloImages + userStat.userGroupImages /* total images the user has been tagged in */}
                                        </td>
                                        {/* Solo Select */}
                                        <td className="w-100">
                                            {userStat.userSoloSelectedImages === 0 ? '0' : userStat.userSoloSelectedImages}
                                        </td>
                                        {/* Group Select */}
                                        <td className="w-100">
                                            {userStat.userGroupSelectedImages === 0 ? '0' : userStat.userGroupSelectedImages}
                                        </td>
                                        {/* Solo Unkilled */}
                                        <td className="w-100">
                                            {userStat.userSoloUnkilledSelects === 0 ? '0' : userStat.userSoloUnkilledSelects}
                                        </td>
                                        {/* Group Unkilled */}
                                        <td className="w-100">
                                            {userStat.userGroupUnkilledSelects === 0 ? '0' : userStat.userGroupUnkilledSelects}
                                        </td>
                                    </tr>
                                ) : null))}
                            </tbody>
                        </table>
                    </div>
                )}
                {currentUser.SelectImageViewOption === ALL_UNKILLED_SELECTS_IMAGES && (
                    <div style={{ width: '100%', minWidth: '600px', margin: '0 auto' }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th colSpan={4} style={{ textAlign: 'center' }}>
                                        <h3>Unkilled Selects</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="kill-allowance-widget-header" style={{ color: 'transparent' }}>Talent</th>
                                    <th className="kill-allowance-widget-header">Total</th>
                                    <th className="kill-allowance-widget-header">Solo</th>
                                    <th className="kill-allowance-widget-header">Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                    <tr key={userStatIndex} style={{ marginTop: 10 }}>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</td>
                                        <td>{userStat.userSoloUnkilledSelects + userStat.userGroupUnkilledSelects}</td>
                                        <td>{userStat.userSoloUnkilledSelects}</td>
                                        <td>{userStat.userGroupUnkilledSelects}</td>
                                    </tr>
                                ) : null))}
                            </tbody>
                        </table>
                    </div>
                )}
                {currentUser.SelectImageViewOption === ALL_IMAGES && (
                    <div style={{ overflowX: 'auto' }} className="kill-allowance-widget-table-container">
                        <table className="viewer-all-table">
                            <thead>
                                <tr>
                                    <th colSpan={1} style={{ color: 'transparent' }}>Talent</th>
                                    <th colSpan={6}><h3>Total Images / Selects</h3></th>
                                    <th colSpan={4}><h3>Unkilled Images / Selects</h3></th>
                                </tr>
                                <tr>
                                    <th style={{ color: 'transparent' }}>Talent</th>
                                    <th>Total</th>
                                    <th className="padding-right">Selects</th>
                                    <th className="padding-left">Solo</th>
                                    <th className="padding-right">Selects</th>
                                    <th className="padding-left">Group</th>
                                    <th className="padding-right">Selects</th>
                                    <th className="padding-before">Solo</th>
                                    <th className="padding-right">Selects</th>
                                    <th className="padding-left">Group</th>
                                    <th className="padding-right">Selects</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(sortedUserStats, (userStat, userStatIndex) => (userStat.allowanceType === 1 && userStat.userType === ROLE_ACTOR ? (
                                    <tr key={userStatIndex}>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{userStat.name}</td>
                                        <td>{userStat.userSoloImages + userStat.userGroupImages}</td>
                                        <td className="padding-right">{userStat.userSoloSelectedImages + userStat.userGroupSelectedImages}</td>
                                        <td className="padding-left">{userStat.userSoloImages}</td>
                                        <td className="padding-right">{userStat.userSoloSelectedImages}</td>
                                        <td className="padding-left">{userStat.userGroupImages}</td>
                                        <td className="padding-right">{userStat.userGroupSelectedImages}</td>
                                        <td className="padding-before">{userStat.soloUnkilled}</td>
                                        <td className="padding-right">{userStat.userSoloUnkilledSelects}</td>
                                        <td className="padding-left">{userStat.groupUnkilled}</td>
                                        <td className="padding-right">{userStat.userGroupUnkilledSelects}</td>
                                    </tr>
                                ) : null))}
                            </tbody>
                        </table>
                    </div>
                )}
                {currentUser.allow === 'none' && (
                    <Row>
                        <Col xs={24}>
                            <p>
                                You do not have access to any images.
                            </p>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
    default:
        return null;
    }
}

ModalContent.defaultProps = {
    killedImagesLink: null,
};

ModalContent.propTypes = {
    type: PropTypes.string.isRequired,
    currentUser: PropTypes.any.isRequired,
    userSoloTotalImages: PropTypes.any.isRequired,
    userGroupTotalImages: PropTypes.any.isRequired,
    productionTotalImages: PropTypes.any.isRequired,
    killedImagesLink: PropTypes.any,
    filteredUserStats: PropTypes.any.isRequired,
    userUpperGroupTotalImages: PropTypes.any.isRequired,
    userLowerGroupTotalImages: PropTypes.any.isRequired,
};

export default ModalContent;
